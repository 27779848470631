<template>
    <div class="col-sm-8">

        <b-form-group label="Individual radios" :required="required" :name="code" :id="code">

            <b-form-radio v-model="selected" name="some-radios" value="A">Option A</b-form-radio>
            <b-form-radio v-model="selected" name="some-radios" value="B">Option B</b-form-radio>

        </b-form-group>

        {{ selected }}

        <!-- <b-form-checkbox
            id="checkbox-1"
            v-model="checked"
            name="checkbox-1"
            unchecked-value="false"
        >

            {{ placeholder }}

        </b-form-checkbox> -->
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'

	export default {
		name: "CheckInput",
        mixins: [],
        props:[
            'placeholder',
            'values',
            'required',
            'code'
        ],
        data() {
            return {
                selected: ''
            }
        },
		mounted() {

		},
		methods: {

        }
    }
</script>
